<template>
    <section class="zc-crear-nuevo mt-4 ml-4" v-loading="loading">
        <div class="row">
            <div class="col-12 pb-3">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'zonas.listado' }">Zonas comunes</el-breadcrumb-item>
                    <el-breadcrumb-item>Guardar zona</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
          <div class="row container-padre custom-scroll overflow-auto" >
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7">
              <div class="row mt-5 align-items-center mb-3">
                <p class="col-12 col-md-6 f-500 f-20 "> Editar zona común</p>
                <template>
                  <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover">
                    <div v-if="zona.estado == 0" class="row mx-0 px-2 py-2 br-4 cr-pointer align-items-center item-popover" @click="activarOinactivarZonaComun(1)">
                      Activar zona
                    </div>
                    <div v-if="zona.estado == 1" class="row mx-0 px-2 py-2 br-4 cr-pointer align-items-center item-popover" @click="activarOinactivarZonaComun(0)">
                      Inactivar zona
                    </div>
                    <div class="row mx-0 px-2 py-2 br-4 cr-pointer align-items-center item-popover" @click="modalEliminarZonaComun()">
                      Eliminar zona
                    </div>
                    <button slot="reference" class="btn-action wh-32">
                      <i class="icon-vertical-points f-15" />
                    </button>
                  </el-popover>
                </template>
              </div>
                <ValidationObserver ref="formCompleto">
                  <div class="row">
                      <div class="col-12 col-md-6 mb-3">
                          <ValidationObserver tag="div" v-slot="{errors}" rules="required|max:70" name="nombre">
                              <p class="f-12 pl-2 label-inputs">Nombre</p>
                              <el-input placeholder="Descripción de la zona" v-model="zona.nombre" show-word-limit maxlength="70" size="small" />
                              <span class="text-danger f-10">{{ errors[0] }}</span>
                          </ValidationObserver>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-md-6 mb-3">
                          <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|max:750" name="descripción">
                              <p class="f-12 pl-2 label-inputs">Descripción</p>
                              <el-input placeholder="Descripción de la zona" v-model="zona.descripcion" type="textarea" show-word-limit maxlength="750"  :autosize="{ minRows: 4, maxRows: 4}" />
                            <span class="text-danger f-10">{{ errors[0] }}</span>
                          </ValidationProvider>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-8 mb-3">
                      <p class="f-12 pl-2 label-inputs">Recomendaciones de uso</p>
                        <el-input placeholder="reglas de la zona" v-model=" zona.reglas" type="textarea" :autosize="{ minRows: 5, maxRows: 5}" disabled />
                        <div class="justify-content-end mx-0 my-2 row">
                            <button class="btn btn-general h-30px f-300 f-14" @click="recomendacionUsoVerMas">Ver mas</button>
                        </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 col-md-6 mb-3">
                          <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|numeric|min_value:1|max_value:65535" name="aforo máximo">
                              <p class="f-12 pl-2 label-inputs">Aforo máximo</p>
                              <el-input placeholder="Número de personas" v-model="zona.aforo_maximo" class="w-90" size="small"  />
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Establece el número máximo de asistentes al mismo tiempo en la zona común.</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            <p class="text-danger col f-10">{{ errors[0] }}</p>
                          </ValidationProvider>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-3">
                        <div class="d-middle">
                            <p class="f-15">Requiere pago</p>
                              <el-switch v-model="zona.pago_tipo" :active-value="1" :inactive-value="0" active-color="var(--color-general)" inactive-color="#E6E6E6" class="mx-2" />
                        </div>
                    </div>
                  </div>
                  <div class="row" v-if="zona.pago_tipo == 1">
                    <div class="col-12 col-md-6 mb-3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|numeric: true|min_value:0|max_value:16777215" name="aforo máximo">
                            <p class="f-12 pl-2 label-inputs">Valor del bloque de reserva</p>
                            <el-input placeholder="Valor" v-model="zona.pago_valor" class="w-90" size="small"  />
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                <template #content>
                                    <p class="f-14 text-general">Indique el valor de uso de esta zona común por bloque de tiempo</p>
                                </template>
                                <i class="icon-help-circle-outline f-20" />
                            </el-tooltip>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 mb-3 my-xl-n3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}"  rules="required|numeric: true|min_value:1|max_value:255" name="tiempo horas">
                            <p class="f-12 pl-2 label-inputs">Tiempo (h) para cancelar reserva después de aprobación</p>
                            <el-input placeholder="Valor en horas" v-model="zona.pago_horas" class="w-90" size="small"  />
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                <template #content>
                                    <p class="f-14 text-general">Estable el numero Máximo de Horas que tiene el residente para pagar la reservacion, después de que el admin apruebe la reserva de lo contrario se cancelara automáticamente.</p>
                                </template>
                                <i class="icon-help-circle-outline f-20" />
                            </el-tooltip>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                  </div>
                  <!-- btn guardar -->
                  <div class="row w-100 my-4 justify-content-end">
                      <button class="btn btn-general h-32px f-300 px-5 f-14" @click="guardarZona">Guardar</button>
                  </div>
                  <!-- Datos adicionales -->
                  <div class="row">
                    <p class="col-12 f-500 f-20 mb-3 mt-5">Datos adicionales</p>
                    <div class="col-12 col-md-6 mb-3">
                            <div class="d-middle">
                                <p class="f-15">Solicitar una explicación para que se va a usar la zona</p>
                                <el-switch v-model="datosAdicionales.uso_necesario" :active-value="1" :inactive-value="0" active-color="var(--color-general)" inactive-color="#E6E6E6" class="mx-2" @change="editarZona($event, 'uso_necesario', [])" />
                            </div>
                        <div class="w-100 mt-2 mt-md-0" v-if="datosAdicionales.uso_necesario">
                            <p class="f-12 pl-2 label-inputs w-100">¿Que explicación se le solicitará al residente?</p>
                            <ValidationProvider tag="div" v-slot="{errors}"  rules="required|max:150" name="explicación">
                                <el-input v-model="datosAdicionales.uso_dato_pedidos" active-color="var(--color-general)" show-word-limit maxlength="150" inactive-color="#E6E6E6" @change="editarZona($event, 'uso_dato_pedidos', errors)" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <div class="w-100">
                            <div class="d-middle">
                                <p class="f-15">Solicitar información de quienes asistirán</p>
                                <el-switch v-model="datosAdicionales.bloques_datos" :active-value="1" :inactive-value="0" active-color="var(--color-general)" inactive-color="#E6E6E6" class="mx-2" @change="editarZona($event, 'bloques_datos', [])" />
                            </div>
                        </div>
                        <div class="w-100 mt-2 mt-md-0" v-if="datosAdicionales.bloques_datos">
                            <p class="f-12 pl-2 label-inputs w-100">¿Que datos solicitará de los asistentes?</p>
                            <div v-if="datosAdicionales.bloques_datos">
                              <ValidationProvider tag="div" v-slot="{errors}"  rules="required|max:50" name="datos solicitados">
                                <el-input v-model="datosAdicionales.bloques_datos_pedidos" active-color="var(--color-general)" inactive-color="#E6E6E6" show-word-limit maxlength="50" @change="editarZona($event, 'bloques_datos_pedidos', errors)" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                              </ValidationProvider>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <p class="col-12 f-500 f-20 mt-5">Limites por reserva</p>
                    <div class="col-12 col-md-6 mb-2">
                      <div class="d-middle my-3">
                        <p class="f-15">Permitir reservas simultáneas</p>
                        <el-switch v-model="datosAdicionales.bloques_compartidos" :active-value="1" :inactive-value="0" active-color="var(--color-general)" inactive-color="#E6E6E6" class="mx-2" @change="editarZona($event, 'bloques_compartidos', [])" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|numeric|min_value:0|max_value:127" name="dias de anterioridad">
                        <p class="f-12 pl-2 label-inputs">Días de anterioridad para reservar</p>
                        <div class="d-middle">
                          <el-input placeholder="Número de horas" v-model="datosAdicionales.dias_antelacion" size="small"/>
                          <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                            <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.dias_antelacion, 'dias_antelacion', errors)"/>
                          </el-tooltip>
                          <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                              <template #content>
                                  <p class="f-14 text-general">Mínimo de días de anterioridad para programar, hoy + días.</p>
                              </template>
                              <i class="icon-help-circle-outline f-20" />
                          </el-tooltip>
                        </div>
                        <p class="text-danger col f-10">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6 mb-3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|numeric|min_value:0|max_value:127" name="días máximos de programación">
                            <p class="f-12 pl-2 label-inputs">Máximo de días de programación</p>
                            <div class="d-middle">
                              <el-input placeholder="Número de días" v-model="datosAdicionales.dias_programacion" class="w-90" size="small"  />
                              <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                              <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.dias_programacion, 'dias_programacion', errors)"/>
                              </el-tooltip>
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Días máximos de programación. Hasta que día futuro se puede reservar. Hoy + días.</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            </div>
                          <p class="text-danger col f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                          <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|numeric|min_value:1|max_value:127" name="cantidad máxima de reservas por día">
                              <p class="f-12 pl-2 label-inputs">Cantidad máxima de reservas totales para un día</p>
                              <div class="d-middle">
                                <el-input placeholder="Número de días" v-model="datosAdicionales.reservas_dia" class="w-90" size="small"  />
                                <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                                  <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.reservas_dia, 'reservas_dia', errors)"/>
                                </el-tooltip>
                                <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                    <template #content>
                                        <p class="f-14 text-general">Cantidad máxima de reservas totales para un día.</p>
                                    </template>
                                    <i class="icon-help-circle-outline f-20" />
                                </el-tooltip>
                              </div>
                            <p class="text-danger col f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6 mb-3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}" rules="required|numeric|min_value:1|max_value:127" name="horas de anterioridad">
                            <p class="f-12 pl-2 label-inputs">Horas de anterioridad para cancelar reserva </p>
                            <div class="d-middle">
                              <el-input placeholder="Número de días" v-model="datosAdicionales.cancelacion_horas" class="w-90" size="small"  />
                              <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                              <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.cancelacion_horas, 'cancelacion_horas', errors)"/>
                              </el-tooltip>
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Define la cantidad de horas anticipadas que se requieren para que el residente pueda cancelar la reserva realizada</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            </div>
                          <p class="text-danger col f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <p class="col-12 f-500 f-20 mb-3 mt-5">Limites por vivienda</p>
                    <div class="col-12 col-md-6 mb-3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}"  rules="required|numeric|max_value:127" name="máximo de reservas por día">
                            <p class="f-12 pl-2 label-inputs">Máximo de reservas por día para una vivienda</p>
                            <div class="d-middle">
                              <el-input v-model="datosAdicionales.bloques_vivienda" placeholder="Número de reservas" class="w-90" size="small" />
                              <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                                <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.bloques_vivienda, 'bloques_vivienda', errors)"/>
                              </el-tooltip>
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Define el límite de reservaciones que puede tener una vivienda en un mismo día.</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            </div>
                          <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}"  rules="required|numeric|max_value:127" name="minimo de días entre reservas">
                            <p class="f-12 pl-2 label-inputs">Mínimo de días entre reservas</p>
                            <div class="d-middle">
                              <el-input v-model="datosAdicionales.reservas_separacion" placeholder="Número de días" class="w-90" size="small" />
                              <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                                  <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.reservas_separacion, 'reservas_separacion', errors)"/>
                              </el-tooltip>
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Establece la cantidad de días que deben pasar para que una vivienda pueda volver a reservar esta zona común.</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                     <div class="col-12 col-md-6 mb-3">
                        <ValidationProvider tag="div" class="w-100" v-slot="{errors}"  rules="required|numeric|max_value:127" name="máximo de personas por reserva">
                            <p class="f-12 pl-2 label-inputs">Máximo de personas por reserva</p>
                            <div class="d-middle">
                              <el-input v-model="datosAdicionales.bloques_personas" placeholder="Número de personas" class="w-90" size="small" />
                              <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                                <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.bloques_personas, 'bloques_personas', errors)"/>
                              </el-tooltip>
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Establece el número máximo de asistentes incluyendo a quien reserva.</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <p class="col-12 f-500 f-20 mb-3 mt-5">Restricciones</p>
                    <div class="col-12 mb-3">
                        <div class="d-middle">
                            <p class="f-15">Deshabilitar reservaciones de viviendas en mora</p>
                              <el-switch v-model="datosAdicionales.deshabilita_mora" :active-value="1" :inactive-value="0" active-color="var(--color-general)" inactive-color="#E6E6E6" class="mx-2" @change="editarZona($event, 'deshabilita_mora', [])"/>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                      <div class="d-middle">
                          <p class="f-15">Requiere aprobación de administración</p>
                            <el-switch v-model="datosAdicionales.aprobacion" :active-value="1" :inactive-value="0"  active-color="var(--color-general)" inactive-color="#E6E6E6" class="mx-2" @change="editarZona($event, 'aprobacion', [])"/>
                      </div>
                    </div>
                  </div>
                  <div v-if="datosAdicionales.aprobacion" class="row">
                    <div class="col-12 mb-3">
                        <ValidationProvider tag="div" v-slot="{errors}"  rules="required" name="tipo de aprobación">
                            <p class="f-15 mb-2">¿Cómo gestionar la reserva?</p>
                            <div class="d-flex f-14 mt-1 my-auto">
                                <label class="d-middle mr-3" for="">
                                    <input v-model="datosAdicionales.aprobacion_automatica" :value="1" type="radio" class="option-input opt-gris radio mr-2" @change="editarZona(datosAdicionales.aprobacion_automatica, 'aprobacion_automatica', errors)"/> De manera manual
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <input v-model="datosAdicionales.aprobacion_automatica" :value="2" type="radio" class="option-input opt-gris radio mr-2" @change="editarZona(datosAdicionales.aprobacion_automatica, 'aprobacion_automatica', errors)"/> Aprobación automática 
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <input v-model="datosAdicionales.aprobacion_automatica" :value="3" type="radio" class="option-input opt-gris radio mr-2" @change="editarZona(datosAdicionales.aprobacion_automatica, 'aprobacion_automatica', errors)"/> Cancelación automática
                                </label>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-md-8 col-lg-auto mb-3">
                        <ValidationProvider tag="div" v-slot="{errors}"  rules="required|max_value:255" name="Horas para aprobar/cancelar automáticamente">
                            <p class="f-12 pl-2 label-inputs">Horas para aprobar/cancelar automáticamente después de reservada</p>
                            <div class="d-middle">
                              <el-input v-model="datosAdicionales.aprobacion_horas" placeholder="Número de horas" class="w-90" size="small" />
                                <el-tooltip popper-class="tooltip-crear-zona" effect="light" content="Editar" placement="bottom">
                                  <i class="icon-ok-circled-outline text-general border f-16 cr-pointer br-5 mx-1 px-1" @click="editarZona(datosAdicionales.aprobacion_horas, 'aprobacion_horas', errors)"/>
                                </el-tooltip>                              
                              <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="tooltip-crear-zona">
                                  <template #content>
                                      <p class="f-14 text-general">Establece las horas máximas que tendrá la solicitud para que se apruebe/cancele automáticamente antes que el administrador tome una decisión.</p>
                                  </template>
                                  <i class="icon-help-circle-outline f-20" />
                              </el-tooltip>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                  </div>
                </ValidationObserver>
                </div>
                 <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5">
              <div class="row mx-0 mt-5 mb-3">
                <p class="col-12 col-md-6 f-500 f-20 px-0"> Imágenes de la zona común</p>
              </div>
              <div class="row mx-0">
                  <el-upload
                    action="#"
                    list-type="picture-card"
                    :file-list="imagenes"
                    :on-change="handleChange"
                    :multiple="false"
                    :auto-upload="false">
                      <i slot="default" class="el-icon-plus" />
                      <div slot="file" slot-scope="{file}">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url" alt=""
                        >
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="eliminarImagen(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
              </div>  
            </div>
                  <div class="row mt-5 w-100">
                    <div class="col-6">
                        <div class="row mx-0">
                            <p class="col-12 f-20 f-500">Horarios</p>
                            <div class="col-12">
                              <el-tabs v-model="diaActivo">
                                    <el-tab-pane v-for="(dia,key) in  horarios.semana" :key="key" :name="dia.dia" :label="dia.dia">
                                        <div class="row">
                                            <div class="col-auto mx-2">
                                                <div class="d-middle">
                                                    <p class="f-15">Cerrado</p>
                                                    <el-checkbox v-model="dia.cerrado" class="mx-2" @change="inactivarDia(key+1)"/>
                                                </div>
                                            </div>
                                            <div v-show="!dia.cerrado" class="row mx-2 col-12">
                                              <div class="col-12 mt-3" v-for="(horario,key2) in dia.horarios" :key="key2" >
                                                <el-time-select is-range arrow-control size="small" class="w-40" align="center"
                                                v-model="horario.hora_apertura"
                                                :disabled="horario.id > 0"
                                                :picker-options="{
                                                  start: key2 == 0 ? '05:00' : dia.horarios[key2-1].hora_cierre,
                                                  step: '00:30',
                                                  end: '24:00',
                                                }"/>
                                                <span> - </span>
                                                <el-time-select is-range arrow-control size="small" class="w-40" align="center"
                                                v-model="horario.hora_cierre"
                                                :disabled="horario.id > 0"
                                                :picker-options="{
                                                  start: horario.hora_apertura,
                                                  step: '00:30',
                                                  end: '24:00',
                                                }"/>
                                                <i v-if="!horario.id" class="icon-circle-add cr-pointer" @click="guardarHorario(horario)"></i>
                                                <i v-if="!horario.id" class="icon-delete-outline cr-pointer" @click="removerHorarioTemp(key2, dia.horarios)"></i>
                                                <i v-else class="icon-close cr-pointer" @click="eliminarHorarioModal(horario.id)"></i>
                                              </div>
                                              <div class="row mx-0 my-3">
                                                  <div class="mx-3 mb-3">
                                                      <p class="text-general f-15 f-500 cr-pointer" @click="agregarHorarioTemp(dia.horarios, key+1)">Agregar horario</p>
                                                  </div>
                                                  <div class="mx-3 mb-3">
                                                      <p class="text-general f-15 f-500 cr-pointer" @click="duplicarHorario(key+1)">Duplicar horario</p>
                                                  </div>
                                              </div>                                              
                                            </div>                                            
                                        </div>                                        
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <p class="col-12 f-20 f-500">Días deshabilitados</p>
                            <p class="col-12 text-general f-15 f-500 cr-pointer mt-3" @click="seleccionarDia">Seleccionar día</p>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 mb-3" v-for="(dia, i) in horarios.dias_deshabilitados" :key="i">
                                <div class="d-flex fechas-deshabilitados br-8 cr-pointer">
                                    <i class="icon-cancelar" @click="eliminarDia(dia.id)" />
                                    <div class="dia br-8 bg-rojo-claro px-3 py-1" @click="editarFechas(dia)">
                                        <div class="d-flex">
                                            <div class="lh-18 my-auto op-05 mx-1">
                                                <p class="f-15 f-500">{{dia.fecha_inicio | helper-fecha('DD')}}</p>
                                                <p class="f-12">{{dia.fecha_inicio | helper-fecha('MMM')}}</p>
                                            </div>                                            
                                            <div class="my-auto op-05 f-500" v-if="dia.fecha_inicio != dia.fecha_fin">-</div>
                                            <div class="lh-18 my-auto op-05 mx-1" v-if="dia.fecha_inicio != dia.fecha_fin">
                                                <p class="f-15 f-500">{{dia.fecha_fin | helper-fecha('DD')}}</p>
                                                <p class="f-12">{{dia.fecha_inicio | helper-fecha('MMM')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="motivo ml-2 my-auto">
                                        <p class="f-15">{{dia.motivo}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
        </div>
        <!-- Partials -->
        <modal-duplicar-horario ref="abrirModalDuplicarHorario" @horarioGuardado="horarioZona" />
        <modal-seleccionar-dia ref="abrirModalSeleccionarDia" @guardarDia="guardarDiaDeshabilitado" />
        <modal-eliminar ref="modalabrirEliminarDia" titulo="Eliminar día de inactividad" mensaje="¿Desea eliminar este día?" tamano="modal-md" class="f-15 text-center" @eliminar="habilitarDia"/>
        <modal-eliminar ref="modalabrirEliminarImagen" titulo="Eliminar Imagen" mensaje="¿Desea eliminar esta imágen?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarImagenRemota" />
        <modal-eliminar ref="modalabrirEliminarHorario" titulo="Eliminar Horario" mensaje="¿Desea eliminar este horario?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarHorario" />
        <modal-eliminar ref="eliminarZonaComun" titulo="Eliminar zona comun" mensaje="¿Desea eliminar la zona comun?" align-text="text-center" @eliminar="eliminarZonaComun">
            <p class="f-15">
              Las reservas realizadas de este zona <br /> desaparecerán
            </p>
        </modal-eliminar>
        <modal-recomendaciones-uso ref="modalRecomendacionesUso" @update="actualizoRecomendacionUso" />
    </section>
</template>
<script>
import Zonas from '~/services/zonas'
import moment from 'moment'
export default {
    components: {
        modalVerImgUnica: () => import('./partials/modalVerImgUnica'),
        modalDuplicarHorario: () => import('./partials/modalDuplicarHorario'),
        modalSeleccionarDia: () => import('./partials/modalSeleccionarDia'),
        modalRecomendacionesUso: () => import('./partials/modalRecomendacionesUso'),
    },
    data(){
        return{
            diaActivo:'Domingo',
            inputNombre:'',
            inputDescripcion:'',
            inputRecomendaciones:'',
            inputAforoMax:'',
            inputExplicacion:'',
            value: '',
            inputValorBloque: '',
            valueSwitch: false,
            listadoImagenes:[
                {
                    src:'/img/pruebas/anuncio1.png',
                    portada: 0,
                },
                {
                    src:'/img/pruebas/anuncio1.png',
                    portada: 1,
                },
                {
                    src:'/img/pruebas/anuncio1.png',
                    portada: 0,
                },
                {
                    src:'/img/pruebas/anuncio1.png',
                    portada: 0,
                },
            ],
            radio_vivienda: 1,
            activeName: 'Domingo',
            fechaHora:[new Date(), new Date()],
            formatHorario : "hh:mm a",

            loading: false,
            zona: {
              id: null,
              nombre: '',
              descripcion: '',
              estado: '',
              reglas: '',
              pago_tipo: 0,
              pago_valor: 0,
              pago_horas: 0,
              aforo_maximo: 0,
              horarios: {
                dias_deshabilitados: []
              },
              imagenes:[]
            },

            imagenes:[],
            disabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            idEliminarImagen: null,
            idEliminarDia: null,
            idEliminarHorario: null,
            datosAdicionales: {
              id: null,
              dias_antelacion: 0,
              dias_programacion: 0,
              bloques_vivienda: 0,
              aprobacion_automatica: 1,
              horarios: {
                dias_deshabilitados: []
              },
              dias_cerrado: [],
              cancelacion_horas: 0
            },
            horarios:{},
        }
    },
    created(){
      this.obtenerZona()
      this.datosAdicionalZona()
      this.listarImagenes()
      this.horarioZona()
    },
    methods:{        
        eliminarImagen(file){ 
          this.idEliminarImagen = file.id
          this.$refs.modalabrirEliminarImagen.toggle()
        },
        duplicarHorario(dia){
            this.$refs.abrirModalDuplicarHorario.setDia(dia)
            this.$refs.abrirModalDuplicarHorario.setIdZona(this.zona.id)
            this.$refs.abrirModalDuplicarHorario.setDiasCerrado(this.datosAdicionales.dias_cerrado)
            this.$refs.abrirModalDuplicarHorario.toggle()
        },
        eliminarHorarioModal(id){ 
          this.idEliminarHorario = id
          this.$refs.modalabrirEliminarHorario.toggle()
        },
        seleccionarDia(){
          this.$refs.abrirModalSeleccionarDia.reset()

          this.$refs.abrirModalSeleccionarDia.toggle()
        },
        editarFechas(dia){ 
          this.$refs.abrirModalSeleccionarDia.setDia(dia)
          this.$refs.abrirModalSeleccionarDia.toggle()
        },
        eliminarDia(id){
          this.idEliminarDia = id
          this.$refs.modalabrirEliminarDia.toggle()
        },
        async obtenerZona(){
          try {
            this.loading = true
            const {data} = await Zonas.obtenerZona(this.$route.params.id_zona)
            this.loading = false
            if(data.success){
              this.zona = data.data
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async listarImagenes(){
          try {
            this.loading = true
            const {data} = await Zonas.listarImagenes(this.$route.params.id_zona)
            this.loading = false
            if(data.success){
              this.imagenes = data.data              
              this.imagenes.forEach(imagen => {
                imagen.url = imagen.imagen
              });
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async subirImagen(file){
          try {
            this.loading = true
            let formData = new FormData();
            formData.append('imagen', file.raw);
            formData.append('id_zona', this.zona.id);
            const {data} = await Zonas.subirImagen(formData)
            this.loading = false
            if(data.success){
              this.listarImagenes()
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async eliminarImagenRemota(){
          try {
            this.loading = true  
            let params = {
              id_zona_imagenes : this.idEliminarImagen
            }          
            const {data} = await Zonas.eliminarImagen(params)
            this.loading = false
            if(data.success){
              this.listarImagenes()
            }
            this.$refs.modalabrirEliminarImagen.toggle()
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async eliminarHorario(){
          try {
            this.loading = true
            const {data} = await Zonas.eliminarHorario(this.idEliminarHorario)
            this.loading = false
            this.$refs.modalabrirEliminarHorario.toggle()
            if(data.success){
              this.horarioZona()
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async habilitarDia(){
          try {
            this.loading = true            
            const {data} = await Zonas.habilitarDia(this.idEliminarDia)
            this.loading = false
            this.$refs.modalabrirEliminarDia.toggle()
            if(data.success){
              this.horarioZona()
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async guardarDiaDeshabilitado(dia){
          try {
            this.loading = true
            let payload = {
              id_zona : this.zona.id,
              motivo : dia.motivo,
              fecha_inicio : moment(dia.fecha_inicio).format('YYYY-MM-DD'),
              fecha_fin: moment(dia.fecha_fin).format('YYYY-MM-DD'),
            }
            const {data} = await Zonas.guardarDiaDeshabilitado(payload)
            this.loading = false
            if(data.success){
              this.horarioZona()
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async inactivarDia(dia){
          try {
            this.loading = true
            let payload = {
              id_zona : this.zona.id,
              dia : dia
            }
            const {data} = await Zonas.inactivarDia(payload)
            this.loading = false
            if(data.success){
              this.horarioZona()
            }
          } catch (e){
            this.loading = false
             this.errorCatch(e)
          }
        },
        async guardarZona(){
          try {
            let validate = await this.$refs.formCompleto.validate()
            if(!validate) return this.notificacion('','Campos incompletos','warning')

            this.loading = true
            const {data} = await Zonas.editar(this.zona.id, this.zona)
              this.loading = false
              if(data.success){
                this.$notify({
                  title: 'Guardar Zona',
                  message: data.mensaje,
                  type: 'success'
                });
                this.obtenerZona()
              }
          } catch (e){
            this.loading = false
            this.errorCatch(e)
          }
        },
        async guardarHorario(horario){
          try {
            if((horario.hora_apertura == "00:00" || horario.hora_cierre == "00:00") || (!horario.hora_apertura || !horario.hora_cierre)) return this.notificacion('','Horas no validas','warning')
             
            this.loading = true
            let payload = {
              id_zona : this.zona.id,
              dia : horario.dia,
              hora_apertura : horario.hora_apertura,
              hora_cierre : horario.hora_cierre
            }
            console.log(payload)
            const {data} = await Zonas.guardarHorario(payload)
            this.loading = false
            if(data.success){
              this.$notify({
                title: 'Guardar Horario',
                message: data.mensaje,
                type: 'success'
              });
              this.horarioZona()
            }else{
              this.$notify({
                title: 'Guardar Horario',
                message: data.mensaje,
                type: 'warning'
              });
            }
          } catch (e){
            this.loading = false
            Object.values(e.response.data.data).flat().forEach(el=>{
    
              setTimeout(() => {
                this.$notify({
                title: 'Guardar Horario',
                message: el,
                type: 'warning'
                });
              }, 300);  
              
            })            
          }
        },
        handleChange(file, ){          
          this.subirImagen(file)
        },
        
        handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
        },
        handleDownload(file) {
          console.log(file);
        },
        agregarHorarioTemp(horarios, dia){
          let horario = {
            dia : dia,
            hora_apertura : "00:00",
            hora_cierre : "00:00"
          }
          horarios.push(horario)
        },
        removerHorarioTemp(index,horarios){
          this.$delete(horarios, index)
        },
        modalEliminarZonaComun(){
          this.$refs.eliminarZonaComun.toggle();
        },
        async eliminarZonaComun(){
          try {

            let params = {
              id_zona: this.$route.params.id_zona
            }
            const { data } = await Zonas.eliminarZonaComun({params})

            this.notificacion('','Zona común eliminada correctamente','success')
            this.$refs.eliminarZonaComun.toggle();
            this.$router.push('/zonas/listado')

          } catch (error) {
            this.errorCatch(error)
          }
        },
        async activarOinactivarZonaComun(nuevoEstado){
          try {

            let params = {
              id_zona: this.$route.params.id_zona,
              estado: nuevoEstado
            }

            const { data } = await Zonas.cambiarEstadoZonaComun(params)

            this.notificacion('',`Zona ${nuevoEstado ? 'activada' : 'inactivada'} correctamente`,'success')
            this.obtenerZona()
          } catch (error) {
            this.errorCatch(error)
          }
        },
        async datosAdicionalZona(){
          try {
              const { data } = await Zonas.datosAdicionalZona(this.$route.params.id_zona)
              this.datosAdicionales = data?.data
          } catch (error) {
            this.errorCatch(error)
          }
        },
        async horarioZona(){
          try {
              const { data } = await Zonas.horarioZona(this.$route.params.id_zona)
              this.horarios = data?.data
          } catch (error) {
            this.errorCatch(error)
          }
        },
        async editarZona(valor, columna, errors){
          try {
            if(errors.length > 0) return;

            this.loading = true

            const payload = {
                campo: columna,
                valor: valor
            }

            const {data} = await Zonas.editarDatosAdicionales(this.zona.id,payload)
            this.loading = false

            if(data.success){
              this.$notify({
                title: 'Guardar Zona',
                message: data.mensaje,
                type: 'success'
              });

              this.datosAdicionalZona()
            }
          } catch (error) {
            this.errorCatch(error)
          }
        },

        recomendacionUsoVerMas(){
          this.$refs.modalRecomendacionesUso.toggle(this.zona.id);
        },

        actualizoRecomendacionUso(valor){
          this.zona.reglas = valor;
        },
    }
}
</script>
<style lang="scss" scoped>
.zc-crear-nuevo{
    .container-padre{
        height: calc(100vh - 125px);
    }
    .icon-help-circle-outline{
        position: relative;
        top: 3px;
        cursor: pointer;
    }
    .container-img{
        width: 120px;
        height: 120px;
        position: relative;
        i.icon-me_interesa{
            position: absolute;
            right: 3px;
            top: 3px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
    .el-tabs__item{
        padding: 0 10px !important;
    }
    .fechas-deshabilitados{
        border-radius: 8px;
        background: var(--color-f5);
        position: relative;
        .icon-cancelar{
            opacity: 0;
            transition: .35s;
            position: absolute;
            right: 5px;
            top: 10px;
            z-index: 1;
        }
        &:hover{
            .icon-cancelar{
                transition: .45s;
                opacity: 1;
            }

        }
    }
}
.disabled-group{
  pointer-events: none !important;
  opacity: .2;
}
.item-popover{
  color: #000000;
  &:hover{
    background-color: #F5F5F5;
  }
}
</style>